<template>
  <div :class="$style.page">
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Фото бренда" prop="image">
        <ImageBulkUploader
          v-model="form.image"
          :text="'Загрузить фото'"
          :class="$style.imageUploader"
        ></ImageBulkUploader>
        <div v-if="form.image[0]" :class="$style.image">
          <img :src="$configData.s3_link + form.image[0]" alt="" />
        </div>
      </el-form-item>
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Название в моем складе" prop="moySkladName">
        <el-input v-model="form.moySkladName" readonly></el-input>
      </el-form-item>
      <el-form-item label="Приоритет" prop="orderField">
        <el-input-number v-model="form.orderField" :min="0"></el-input-number>
      </el-form-item>
      <el-button type="primary" @click="submitForm()"> Сохранить </el-button>
      <el-button type="primary" @click="submitFormAndReturn()">
        Сохранить и вернуться
      </el-button>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import ImageBulkUploader from '@/components/moleculs/ImageBulkUploader'

import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'

export default {
  components: {
    ImageBulkUploader,
  },
  ADDSELLERS_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      form: {
        image: '',
        name: '',
        moySkladName: '',
        orderField: 0,
      },
      rules: {
        image: [
          {
            required: true,
            message: 'Пожалуйста, загрузите фото бренда',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  async created() {
    await this.getBrand()
  },
  methods: {
    async getBrand() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } = await delivery.AddwineCore.BrandsActions.getById(
        this.$route.params.id,
      )

      loading.close()

      if (error) return

      this.form = { ...value, image: [value.image] }
    },
    async submitForm() {
      const valid = await new Promise((resolve) => {
        this.$refs.form.validate((valid) => resolve(valid))
      })

      if (valid) {
        const loading = this.$loading({
          lock: true,
        })

        const { error } = await delivery.AddwineCore.BrandsActions.update(
          this.$route.params.id,
          {
            ...this.form,
            image: this.form.image[0],
          },
        )

        loading.close()

        if (!error) {
          this.showNotification('Бренд успешно отредактирован', 'success')
          return true
        }
      }

      return false
    },
    async submitFormAndReturn() {
      const isSuccess = await this.submitForm()

      if (isSuccess) {
        this.$router.go(-1)
      }
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .form {
    & > div > label {
      text-align: left;
    }

    .image {
      width: 12rem;
      height: 12rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
